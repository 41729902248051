import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  error: {},
  admins: {},
  userGroups: {},
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getAdminsSuccess(state, action) {
      state.isLoading = false;
      state.admins = action.payload;
    },
    getUserGroupsSuccess(state, action) {
      state.isLoading = false;
      state.userGroups = action.payload;
    },
  },
});

export default adminSlice.reducer;
