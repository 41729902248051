import classes from "./Abilities.module.css";
import { abilitiesResult } from "../../../../../../constants";

const Abilities = ({ data }) => {
  return (
    <div className={`relative ${classes.mainContainer}`} type="container">
      <h2>Abilities</h2>
      <div>
        {abilitiesResult.map((item, index) => (
          <div className={`flex t-14 ${classes.flex}`} key={index}>
            <span className={`center-flex mt-sm`}>{item.skill}</span>

            {(!data?.[item.id] || data?.[item.id] === "0") && (
              <span className={`center-flex mt-sm ${classes.red}`}>Poor</span>
            )}
            {data?.[item.id] === "1" && (
              <span className={`center-flex mt-sm ${classes.yellow}`}>
                Fair
              </span>
            )}
            {data?.[item.id] === "2" && (
              <span className={`center-flex mt-sm ${classes.green}`}>Good</span>
            )}
            {data?.[item.id] === "3" && (
              <span className={`center-flex mt-sm ${classes.green}`}>
                Very good
              </span>
            )}
            {data?.[item.id] === "4" && (
              <span className={`center-flex mt-sm ${classes.green}`}>
                Excellent
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Abilities;
