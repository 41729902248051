import classes from "./ComingSoon.module.css";
import { StanOpenHands } from "../../constants/stan";
import Button from "../../components/UI/Button/Button";
import { useNavigate } from "react-router";

const ComingSoon = () => {
  const navigate = useNavigate()

  return (
    <div className={`${classes.main} flex flex-col items-center`}>
      <StanOpenHands shirtColor={"#030659"} />
      <h2 className="mt-sm mb-md">Sorry, this feature is still being worked on</h2>
      <p className="mb-sm t-14">
        Click below to get back
      </p>
      <Button onClick={() => navigate(-1)} variant="yellow">
        Go Back
      </Button>
    </div>
  );
};

export default ComingSoon;
