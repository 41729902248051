import classes from "./Education.module.css";

const Education = ({ education }) => {
  return (
    <div type="container" className="relative">
      <h3>Education</h3>
      {education?.length < 1 && <div className={`t-14 center mt-4`}>
        No past education provided
      </div>}
      {education?.map((item, index) => (
        <div key={index}>
          <div className="mt-sm">
            <p>{item.schoolName}</p>
            <div className={`flex mt-sm ${classes.align}`}>
              <span className={classes.industry}>{item.fieldOfStudy}</span>
              <span className={classes.span}></span>
              <span>{item.degree}</span>
            </div>
          </div>
          <br />
        </div>
      ))}
      {/* <span className={classes.line}></span>
      <h3 className={classes.topTitle}>Certificate</h3>
      <div className="mt-sm">
        <p>Certified Networking Professional</p>
        <p className="mt-sm">Agile Project Mangement(PMI-CP)</p>
      </div>
      <br />
      <span className={classes.line}></span>
      <h3 className={classes.topTitle}>Portfolio</h3>
      <div className="mt-sm">
        <p>Certified Networking Professional</p>
        <p className="mt-sm">Agile Project Mangement(PMI-CP)</p>
      </div> */}
    </div>
  );
};

export default Education;
