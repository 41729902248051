import classes from "./Profile.module.css";
import { Briefcase, Location, LinkedIn, Website } from "../../../../../../constants";
import { useMemo } from "react";

const Profile = ({ data }) => {
  const age = useMemo(() => {
    const year = new Date(data?.dateOfBirth).getFullYear();
    const now = new Date().getFullYear();
    return +now - +(year || now);
  }, [data]);
  const job = useMemo(() => {
    return data?.workHistoryExperience?.[0]?.jobTitle;
  }, [data]);
  const workPlace = useMemo(() => {
    return data?.workHistoryExperience?.[0]?.companyName;
  }, [data]);
  return (
    <div className="relative" type="container">
      <div className={`flex ${classes.main}`}>
        <div className={`flex ${classes.imgDiv}`}>
          <div className={classes.img}>
            {data?.firstName?.split("")[0]?.toUpperCase() ||
              data?.email?.split("")[0]?.toUpperCase()}
          </div>
          <div>
            <h3 className="mb-sm">
              {!data.firstName && !data.lastName ? '- -' : `${data.firstName ?? ''} ${data.lastName ?? ''}`}
            </h3>
            <p className={`mb-sm ${classes.jobStyle}`}>
              {job ? `${job}, ` : ''}{workPlace}
            </p>
            <div className={`flex`}>
              <span>
                <Location />
                {data?.permanentAddress || '-'}
              </span>
              <span>
                <Briefcase /> {age} years
              </span>
            </div>
          </div>
        </div>
        <div className={classes.colTwo}>
          <div className={`flex column`}>
            <span className="mb-sm">
              <Website />
              {data?.webSiteUrl || '-'}
            </span>
            <span>
              <LinkedIn /> {data?.linkedInUrl || '-'}
            </span>
          </div>
          <span className={classes.border}></span>
          <div className={`flex column`}>
            <span className="mb-sm break-all">{data?.email}</span>
            <span>{data?.workMode}</span>
          </div>
        </div>
      </div>
      <br />
      {data?.summary && <div>
        <h3>Summary</h3>
        <span className={`mt-sm t-14 ${classes.summary}`}>{data?.summary}</span>
      </div>}
    </div>
  );
};

export default Profile;
