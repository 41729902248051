export const DASHBOARD_ACTIVITY_SUMMARY = [
    {
        message: "Jane Doe just got unmatched from Alpha Beta Technologies ",
        time: "1 day ago",
    },
    {
        message: "Jane Doe just got a gig with Alpha Beta Technologies",
        time: "1 day ago",
    },
    {
        message: "Jane Doe just got unmatched from Alpha Beta Technologies ",
        time: "1 day ago",
    },
    {
        message: "Jane Doe just got unmatched from Alpha Beta Technologies ",
        time: "1 day ago",
    },
    {
        message: "Jane Doe just got unmatched from Alpha Beta Technologies ",
        time: "1 day ago",
    },
];

export const DASHBOARD_JOB_SUMMARY = [
    {
        message: "2 roles added from Alpha Beta Technologies ",
        time: "1 day ago",
    },
    {
        message: "2 roles added from Alpha Beta Technologies ",
        time: "1 day ago",
    },
    {
        message: "1 role added from Alpha Beta Technologies ",
        time: "1 day ago",
    },
    {
        message: "2 roles added from Alpha Beta Technologies ",
        time: "1 day ago",
    },
];
