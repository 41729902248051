import Logo from "../../../assets/images/awasource/logo.png";
import classes from "./loader.module.css";

const FullScreenLoader = () => {
  return (
    <div className={classes.parent}>
      <div>
        <img src={Logo} alt="loader" />
        <div className={classes.lds_ring}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenLoader;
