import { adminRoute } from "../base";
import httpService from "../base/httpService";

export const getClientsApi = async (page = 1, limit = 10, order = 'desc') => {
  const response = await httpService.get(`${adminRoute}/clients?page=${page}&limit=${limit}&order=${order}`);
  return response;
};

export const updateClientApi = async (id = '', data) => {
  const response = await httpService.put(`${adminRoute}/update/client/${id}`, data);
  return response;
};
