import { adminRoute } from "../base";
import httpService from "../base/httpService";

export const getTalentsApi = async (page = 1, limit = 10, order = 'desc') => {
  const response = await httpService.get(`${adminRoute}/talents?page=${page}&limit=${limit}&order=${order}`);
  return response;
};

export const updateTalentApi = async (id = '', data) => {
  const response = await httpService.put(`${adminRoute}/update/talent/${id}`, data);
  return response;
};

