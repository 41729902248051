import Button from "../../../../../components/UI/Button/Button";
import RadioInput from "../../../../../components/UI/RadioInput/input";
import classes from "./index.module.css";
import { useLocation, useNavigate } from "react-router";
import { Formik } from "formik";
import { updateClientApi } from "../../../../../apis";
import { toast } from "react-toastify";
import { errorHandler } from "../../../../../utils/errorHandler";

const AddNewClient = ({ title = "Add" }) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div>
      <h2>{title} Client</h2>
      <Formik
        initialValues={{
          firstName: state?.firstName || "",
          lastName: state?.lastName || "",
          email: state?.email || "",
          phone: state?.phone || "",
          companyName: state?.companyName || "",
          companyType: state?.companyType || "",
          companySize: state?.companySize || "",
          status: state?.status || "Active",
          linkedInUrl: state?.linkedInUrl || "",
          employeeIdentificationNumber: state?.employeeIdentificationNumber || ""
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          try {
            const { data } = await updateClientApi(state._id, values);
            if (data) {
              toast.success('Update was successful');
              navigate(-1)
            }
          } catch (error) {
            errorHandler(error, Object.values(error.errors)[0])
          }
          setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} className={classes.container}>
            <div className={classes["form-group"]}>
              <label>
                First Name*
                <input required type="text" placeholder="Jane" className="mt-sm" {...getFieldProps("firstName")} />
              </label>
              <label>
                Last Name*
                <input required type="text" placeholder="Jane" className="mt-sm" {...getFieldProps("lastName")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Company Name*
                <input required type="text" placeholder="Awasource" className="mt-sm" {...getFieldProps("companyName")} />
              </label>
              <label>
                Work Email*
                <input required type="email" placeholder="Janedoe@awasource.com" className="mt-sm" {...getFieldProps("email")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Position*
                <select required name="emailType" onChange={handleChange} className="mt-sm" {...getFieldProps("position")}>
                  <option>None</option>
                  <option>Project Manager</option>
                </select>
              </label>
              <label>
                Phone Number*
                <input required type="text" placeholder="Phone Number" className="mt-sm" {...getFieldProps("phone")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Facebook Url
                <input type="text" placeholder="Facebook Url" className="mt-sm" {...getFieldProps("facebookUrl")} />
              </label>
              <label>
                Twitter Url
                <input type="text" placeholder="Twitter Url" className="mt-sm" {...getFieldProps("twitterUrl")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                LinkedIn Url
                <input type="text" placeholder="LinkedIn Url" className="mt-sm" {...getFieldProps("linkedInUrl")} />
              </label>
              <label>
                Instagram Url
                <input type="text" placeholder="Instagram Url" className="mt-sm" {...getFieldProps("instagramUrl")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Summary
                <textarea placeholder="I am lorem ipsum etc etc" className="mt-sm" {...getFieldProps("summary")} rows={5} />
              </label>
              <label>
                pic
              </label>
            </div>

            <div className={classes.radios}>
              <p>Company type</p>
              <div>
                <RadioInput
                  text="Individual"
                  value="Individual"
                  name="companyType"
                  checked={values.companyType === "Individual"}
                  onChange={(e) => {
                    if (e.target.checked)
                      setFieldValue('companyType', e.target.value);
                  }}
                />

                <RadioInput
                  text="Company"
                  value="Company"
                  name="companyType"
                  checked={values.companyType === "Company"}
                  onChange={(e) => {
                    if (e.target.checked)
                      setFieldValue('companyType', e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={classes["form-group"]}>
              <label>
                Year of Establishment
                <input type="date" placeholder="DD/MM/YYYY" className="mt-sm" {...getFieldProps("year")} />
              </label>
              <label>
                Company Website Url
                <input type="text" placeholder="website url" className="mt-sm" {...getFieldProps("website")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label className="col-span-2">
                Company address
                <input type="text" placeholder="Company address" className="mt-sm" {...getFieldProps("address")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                CAC number*
                <input type="text" placeholder="CAC number" className="mt-sm" {...getFieldProps("cacNumber")} />
              </label>
              <label>
                Driver's license number*
                <input type="text" placeholder="Driver's license number" className="mt-sm" {...getFieldProps("license")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                National Identification Number*
                <input type="text" placeholder="National Identification Number" className="mt-sm" {...getFieldProps("nin")} />
              </label>
              <label>
                International passport number*
                <input type="text" placeholder="International passport number" className="mt-sm" {...getFieldProps("passport")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Employee Identification number*
                <input type="text" placeholder="Employee Identification number" className="mt-sm" {...getFieldProps("employeeIdentificationNumber")} />
              </label>
              <label>
                Tax Identification Number*
                <input type="text" placeholder="Tax Identification Number" className="mt-sm" {...getFieldProps("tin")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Password
                <input type="text" placeholder="Password" className="mt-sm" {...getFieldProps("password")} />
              </label>
              <label>
                Confirm Password
                <input type="text" placeholder="Confirm Password" className="mt-sm" {...getFieldProps("confirm_password")} />
              </label>
            </div>

            <div className={classes.btn}>
              <Button variant="white" isLink to="/dashboard/users/clients">
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting} variant="yellow">Save</Button>
              <Button type="submit" disabled={isSubmitting} variant="blue">Save and notify</Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddNewClient;
