import { adminRoute } from "../base";
import httpService from "../base/httpService";

export const getAdminsApi = async (page = 1, limit = 10, order = 'desc') => {
  const response = await httpService.get(`${adminRoute}/admins?page=${page}&limit=${limit}&order=${order}`);
  return response;
};

export const getUserGroupsApi = async (page = 1, limit = 10, order = 'desc') => {
  const response = await httpService.get(`${adminRoute}/admin-user-groups?page=${page}&limit=${limit}&order=${order}`);
  return response;
};

export const addAdminApi = async (data) => {
  const response = await httpService.post(`${adminRoute}/add/admin`, data);
  return response;
};

export const updateAdminApi = async (id = '', data) => {
  const response = await httpService.put(`${adminRoute}/update/admin/${id}`, data);
  return response;
};

export const addUserGroupApi = async (data) => {
  const response = await httpService.post(`${adminRoute}/add/admin-user-group`, data);
  return response;
};

export const updateUserGroupApi = async (id = '', data) => {
  const response = await httpService.put(`${adminRoute}/update/admin-user-group/${id}`, data);
  return response;
};
