import classes from "./Jobs.module.css";
import Edit from "../../../../assets/images/icons/edit.svg";
import Delete from "../../../../assets/images/icons/delete.svg";
import Archive from "../../../../assets/images/icons/archive.svg";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dispatch, useSelector } from "../../../../redux/store";
import { getJobs } from "../../../../redux/actions/jobActions";
import FullScreenLoader from "../../../../components/UI/FullScreenLoader/FullScreenLoader";

const Jobs = () => {
  const { isLoading, jobs } = useSelector(store => store.job);

  const navigate = useNavigate();

  const jobStatus = useMemo(() => (['in-progress', 'closed', 'blocked', 'pending', 'approved']), [])
  const [currentPage, setCurrentPage] = useState("approved");

  const jobsByStatus = useMemo(() => (jobs?.records?.reduce((acc, curr) => {
    const key = curr?.jobStatus || jobStatus[0];
    if (acc[key]) {
      acc[key] = [...acc[key], curr]
    } else {
      acc[key] = [curr]
    }

    return acc;
  }, {})), [jobStatus, jobs])

  useEffect(() => {
    dispatch(getJobs());
  }, [])

  return (
    <div>
      {isLoading && <FullScreenLoader />}
      <h2>Jobs</h2>
      <div className={`flex gap-4 justify-between mt-sm ${classes.btn}`}>
        <div className={`flex gap-2 flex-wrap flex-grow`}>
          {jobStatus?.map((item, i) => (
            <button
              key={`btn-${i}`}
              onClick={() => setCurrentPage(item)}
              className={currentPage === item ? classes.active : ""}
            >
              {item?.split("-").join(" ")} &nbsp; {jobsByStatus?.[item]?.length}
            </button>
          ))}
          {/* <Button variant="yellow">Create Jobs</Button> */}
        </div>
        <input placeholder="Search" className="max-w-full w-[230px]" />
      </div>
      <div className={`mt-md ${classes.table}`}>
        <div className={classes.header}>
          <span>Title</span>
          <span>Location</span>
          <span>Type</span>
          <span>Date</span>
          <span>Salary</span>
          <span>Candidate</span>
          <span>Actions</span>
        </div>
        <hr />
        {
          jobsByStatus?.[currentPage]?.length > 0 ?

            jobsByStatus?.[currentPage]?.map(
              (
                item,
                i
              ) => (
                <div
                  className={classes.grid}
                >
                  <div>
                    <h3>{item?.title}</h3>
                    <p>{item?.jobType}</p>
                  </div>
                  <span>{item?.location}</span>
                  <span>{item?.duration}</span>
                  <span>{item?.time}</span>
                  <span>{item?.budget}</span>
                  <span>{item?.applicants?.length}</span>
                  <div>
                    <button onClick={() => navigate(`/dashboard/jobs/details`, { state: item })}>
                      <img src={Edit} alt="" />
                    </button>
                    <button>
                      <img src={Delete} alt="" />
                    </button>
                    <button>
                      <img src={Archive} alt="" />
                    </button>
                  </div>
                </div>
              )
            ) : (
              <p className="text-center py-4">No jobs found</p>
            )}
      </div>
    </div>
  );
};

export default Jobs;
