import { Link } from "react-router-dom";
import classes from "./Button.module.css";

const Button = ({ isLink, to, onClick, children, variant, className, disabled = false, type = "button" }) => {
  return isLink ? (
    <Link
      to={to}
      className={`min-w-[100px] ${classes.btn} ${variant === "yellow" ? classes.yellow : ""} 
        ${variant === "white" ? classes.white : ""}
        ${variant === "blue" ? classes.blue : ""} !flex items-center justify-center ${className}`}
    >
      {children}
    </Link>
  ) : (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`min-w-[100px] ${classes.btn} ${variant === "yellow" ? classes.yellow : ""} 
    ${variant === "white" ? classes.white : ""}
    ${variant === "blue" ? classes.blue : ""} !flex items-center justify-center ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
