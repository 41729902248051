import Button from "../../../components/UI/Button/Button";
import { ALL_EMAILS } from "../../../constants";
import classes from "./Emails.module.css";
import Edit from "../../../assets/images/icons/edit.svg";
import Delete from "../../../assets/images/icons/delete.svg";
import Archive from "../../../assets/images/icons/archive.svg";
import { useNavigate } from "react-router";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const Emails = () => {
  const navigate = useNavigate();

  return (
    <div>
      <h2>Email Management</h2>
      <div className={`mt-md ${classes.table}`}>
        <div className={`flex flex-col md:flex-row md:items-center gap-4 mb-md ${classes.btn}`}>
          <div className={`flex items-center ${classes.otherbutton}`}>
            <Button variant="white">Activate</Button>
            <Button variant="white">Delete</Button>
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center gap-4">
            <input placeholder="Search" />
            <Button
              variant="yellow"
              onClick={() => navigate("/dashboard/add-email")}
            >
              Add Email
            </Button>
          </div>
        </div>

        <DataTable
          emptyMessage="No record found"
          rows={10}
          paginator={ALL_EMAILS.length > 10}
          value={ALL_EMAILS || []}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: '100%' }}
        >
          <Column className="pr-3 text-center"
            field="title"
            header={
              <input type="checkbox" className="w-[18px] h-[18px] rounded-s" />}
            body={(item) => (
              <div className="text-left">
                <input type="checkbox" className="w-[18px] h-[18px] rounded-s" />
              </div>
            )}
          />
          <Column className="p-5 text-center"
            field="title"
            header="Name"
            body={(item) => (
              <div className="text-left">{item.title}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="subject"
            header="Subject"
            body={(item) => (
              <div className={classes.subject}>{item.subject}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="type"
            header="Type"
            body={(item) => (
              <div>{item.type}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="status"
            header="Status"
            body={(item) => (
              <div className="rounded-full bg-[#F5F6FA] h-[38px] w-[38px] flex items-center justify-center">
                {item.status?.charAt(0)}
              </div>)}
            sortable
          />
          <Column className="p-5" header="Actions" body={(item) => (
            <div className="flex items-center gap-2">
              <button className={`relative ${classes.edit}`}
                onClick={() => navigate("/dashboard/edit-email")}>
                <img src={Edit} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Edit Email
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
              <button className={`relative ${classes.delete}`}>
                <img src={Delete} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Delete Email
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
              <button className={`relative ${classes.archive}`}>
                <img src={Archive} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Archive Email
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
            </div>)} />
        </DataTable>
      </div>
    </div>
  );
};

export default Emails;
