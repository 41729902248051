import classes from "./Profile.module.css";
import Profile from "../Review/Profile/Profile";
import Skills from "../Review/Skills/Skills";
import Abilities from "../Review/Abilities/Abilities";
import WorkPriorities from "../Review/WorkPriorities/WorkPriorities";
import Resume from "../Review/Resume/Resume";
import WorkHistory from "../Review/WorkHistory/WorkHistory";
import Education from "../Review/Education/Education";
import { useLocation } from "react-router";

const TalentProfile = () => {
  const { state } = useLocation();

  return (
    <>
      <div className={classes.main}>
        <h2 className="text-lg md:text-2xl mb-md">Talent Profile</h2>
        <Profile data={state} />
        <div className={`mt-md ${classes.grid}`}>
          <div>
            <Skills data={state} />
            <Abilities data={state?.abilities} />
            <WorkPriorities data={state} />
            {state?.pdfUrl && <Resume data={state} />}
          </div>
          <div>
            <WorkHistory data={state} />
            <Education education={state?.educations} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TalentProfile;
