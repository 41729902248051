import Button from "../../../../components/UI/Button/Button";
import classes from "./UserGroupManagement.module.css";
import Edit from "../../../../assets/images/icons/edit.svg";
import Delete from "../../../../assets/images/icons/delete.svg";
import Archive from "../../../../assets/images/icons/archive.svg";
import Mail from "../../../../assets/images/icons/mail.svg";
import { useCallback, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "../../../../redux/store";
import { useNavigate } from "react-router";
import { getAdminUserGroups } from "../../../../redux/actions/adminActions";
import FullScreenLoader from "../../../../components/UI/FullScreenLoader/FullScreenLoader";

const UserGroupManagement = () => {
  const [checkAll, setCheckAll] = useState(true);
  const { isLoading, userGroups } = useSelector(store => store.admin);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    sortField: null,
    sortOrder: null,
  });

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const handleCheckAll = (e) => {
    setCheckAll(e.target.checked);
  };

  const onLazyLoad = useCallback((event) => {
    setLazyParams(event);
  }, [setLazyParams]);
  
  useEffect(() => {
    dispatch(getAdminUserGroups(((lazyParams?.page || 0) + 1), lazyParams?.rows));
  }, [dispatch, lazyParams?.page, lazyParams?.rows])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {isLoading && <FullScreenLoader />}
      <h2>Admin Management</h2>
      <div className={`mt-md ${classes.table}`}>
        <div className={`flex flex-col md:flex-row md:items-center gap-4 mb-md ${classes.btn}`}>
          <div className={`flex items-center ${classes.otherbutton}`}>
            <Button variant="white">Delete</Button>
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center gap-4">
            <Button variant="yellow" isLink to="/dashboard/admin/user-group/add">
              Add User Group
            </Button>
          </div>
        </div>

        <DataTable
          value={userGroups?.records || []}
          lazy
          paginator
          rows={lazyParams.rows}
          totalRecords={userGroups?.totalItems}
          loading={isLoading}
          onPage={onLazyLoad}
          first={lazyParams.first}
          emptyMessage="No record found"
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: '100%' }}
        >
          <Column className="pr-3 text-center"
            field="title"
            header={
              <input type="checkbox" className="w-[18px] h-[18px] rounded-s" />}
            body={(item) => (
              <div className="text-left">
                <input type="checkbox" className="w-[18px] h-[18px] rounded-s" />
              </div>
            )}
          />
          <Column className="p-5 text-center"
            field="name"
            header="Full Name"
            body={(item) => (
              <div className="text-left min-w-[150px]">{`${item.firstName ?? ''} ${item.lastName ?? ''}`}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="email"
            header="Email"
            body={(item) => (
              <div className={classes.subject}>{item.email}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="updatedAt"
            header="Last Updated"
            body={(item) => (
              <div>{item.updatedAt ? new Date(item.updatedAt).toLocaleDateString() : ""}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="firstName"
            header="Image"
            body={(item) => (
              <div className="rounded-full bg-[#F5F6FA] h-[38px] w-[38px] flex items-center justify-center font-semibold">
                {(item.firstName || item.email)?.charAt(0)?.toUpperCase()}
              </div>)}
          // sortable
          />
          <Column className="p-5" header="Actions" body={(item) => (
            <div className="flex items-center gap-2 min-w-[185px]">
              <button className={`relative ${classes.edit}`} onClick={() => navigate(`/dashboard/users/admins/edit`, { state: item })}>
                <img src={Edit} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Edit User Group
                    <span className={classes.arrowdown} ></span>
                  </span>
                </div>
              </button>
              <button className={`relative ${classes.delete}`}>
                <img src={Delete} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Delete User Group
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
              <button className={`relative ${classes.archive}`}>
                <img src={Archive} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Archive User Group
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
              <button className={`relative ${classes.archive}`}>
                <img src={Mail} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Email User Group
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
            </div>)} />
        </DataTable>
      </div>
    </div>
  );
};

export default UserGroupManagement;
