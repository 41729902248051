import classes from "./Header.module.css";
import Message from "../../../assets/images/icons/message.svg";
import { Bell, Logo } from "../../../constants/svgs";
import { dispatch, useSelector } from "../../../redux/store";
import { useEffect } from "react";
import { getAdminData } from "../../../redux/actions/authActions";

const Header = ({ side, setSide }) => {
  const { auth, profile } = useSelector(store => store.auth);

  useEffect(() => {
    if (!profile) {
      dispatch(getAdminData(auth.adminId));
    }
  }, [auth, profile])

  return (
    <header className={`flex ${classes.header}`}>
      <div
        className={`${classes.menu} ${side ? classes.cancel : ""}`}
        onClick={() => setSide(!side)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={classes.logo}>
        <Logo color="#030659" />
      </div>
      <div className={`flex ${classes.container}`}>
        <Bell />
        <img src={Message} alt="Message" />
        <span className={classes.line}></span>
        <div className={`${classes.company}`}>
          <span className={`center-flex ${classes.checkContainer}`}>
            <span className={classes.check}></span>
          </span>
          <span className="max-w-28 truncate overflow-ellipsis">{profile?.email || "- -"}</span>
        </div>
      </div>
    </header>
  );
};

export default Header;
