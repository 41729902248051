import { jobSlice as slice } from '../slices/jobSlice';
import { dispatch } from '../store';
import { errorHandler } from '../../utils/errorHandler';
import { getJobsApi } from '../../apis';

export function getJobs(page = 1, limit = 10) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await getJobsApi(page, limit);

      dispatch(slice.actions.getJobsSuccess(data));
    } catch (err) {
      errorHandler(err, "Failed to get jobs!", false);
      dispatch(slice.actions.hasError(err));
    }
  };
}
