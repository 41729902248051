import Button from "../../../../../components/UI/Button/Button";
import classes from "./Talents.module.css";
import Edit from "../../../../../assets/images/icons/edit.svg";
import Delete from "../../../../../assets/images/icons/delete.svg";
import Archive from "../../../../../assets/images/icons/archive.svg";
import Mail from "../../../../../assets/images/icons/mail.svg";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../../../../redux/store";
import { getTalents } from "../../../../../redux/actions/talentActions";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router";
import FullScreenLoader from "../../../../../components/UI/FullScreenLoader/FullScreenLoader";
import { Link } from "react-router-dom";

const Talents = () => {
  const [checkAll, setCheckAll] = useState(true);
  const { isLoading, talents } = useSelector(store => store.talent);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    sortField: null,
    sortOrder: null,
  });

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const handleCheckAll = (e) => {
    setCheckAll(e.target.checked);
  };

  useEffect(() => {
    dispatch(getTalents(((lazyParams?.page || 0) + 1), lazyParams?.rows));
  }, [dispatch, lazyParams?.page, lazyParams?.rows])

  const onLazyLoad = useCallback((event) => {
    setLazyParams(event);
  }, [setLazyParams]);

  return (
    <div>
      {isLoading && <FullScreenLoader />}

      <h2>Talents</h2>
      <div className={`mt-md ${classes.table}`}>
        <div className={`flex flex-col md:flex-row md:items-center gap-4 mb-md ${classes.btn}`}>
          <div className={`flex items-center ${classes.otherbutton}`}>
            <Button variant="white">Email</Button>
            <Button variant="white">Delete</Button>
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center gap-4">
            <input placeholder="Search Talents" />
            {/* <Button
              variant="yellow"
            // onClick={() => navigate("/dashboard/add-email")}
            >
              Add Talents
            </Button> */}
          </div>
        </div>

        <DataTable
          value={talents.records || []}
          lazy
          paginator
          rows={lazyParams.rows}
          totalRecords={talents.totalItems}
          loading={isLoading}
          onPage={onLazyLoad}
          first={lazyParams.first}
          emptyMessage="No record found"
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: '100%' }}
        >
          <Column className="pr-3 text-center"
            field="title"
            header={
              <input type="checkbox" className="w-[18px] h-[18px] rounded-s" />}
            body={(item) => (
              <div className="text-left">
                <input type="checkbox" className="w-[18px] h-[18px] rounded-s" />
              </div>
            )}
          />
          <Column className="p-5"
            field="firstName"
            header="Full Name"
            body={(item) => (
              <Link to="/dashboard/users/talents/view" state={item} className="text-left min-w-[150px] underline">{!item.firstName && !item.lastName ? '- -' : `${item.firstName ?? ''} ${item.lastName ?? ''}`}
              </Link>)}
            sortable
          />
          <Column className="p-5"
            field="email"
            header="Email"
            body={(item) => (
              <Link to="/dashboard/users/talents/view" state={item} className={`${classes.subject} underline`}>{item.email}
              </Link>)}
            sortable
          />
          <Column className="p-5"
            field="updatedAt"
            header="Last Updated"
            body={(item) => (
              <div>{item.updatedAt ? new Date(item.updatedAt).toLocaleDateString() : ""}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="firstName"
            header="Image"
            body={(item) => (
              <div className="rounded-full bg-[#F5F6FA] h-[38px] w-[38px] flex items-center justify-center font-semibold">
                {(item.firstName || item.email)?.charAt(0)?.toUpperCase()}
              </div>)}
          // sortable
          />
          <Column className="p-5" header="Actions" body={(item) => (
            <div className="flex items-center gap-2 min-w-[185px]">
              <button className={`relative ${classes.edit}`} onClick={() => navigate(`/dashboard/users/talents/edit`, { state: item })}>
                <img src={Edit} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Edit Talent
                    <span className={classes.arrowdown} ></span>
                  </span>
                </div>
              </button>
              <button className={`relative ${classes.delete}`}>
                <img src={Delete} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Delete Talent
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
              <button className={`relative ${classes.archive}`}>
                <img src={Archive} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Archive Talent
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
              <button className={`relative ${classes.archive}`}>
                <img src={Mail} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Email Talent
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
            </div>)} />
        </DataTable>
      </div>
    </div>
  );
};

export default Talents;
