import Button from "../../../../../components/UI/Button/Button";
import RadioInput from "../../../../../components/UI/RadioInput/input";
import classes from "./index.module.css";
import { useLocation, useNavigate } from "react-router";
import { Formik } from "formik";
import CheckInput from "../../../../../components/UI/CheckInput/input";
import { updateTalentApi } from "../../../../../apis";
import { errorHandler } from "../../../../../utils/errorHandler";
import { toast } from "react-toastify";
import { useRef, useState } from "react";

const AddNewTalent = ({ title = "Add" }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log({ state })

  let min = 0;
  let max = 500;
  const [minVal, setMinVal] = useState(0);
  const [maxVal, setMaxVal] = useState(500);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  return (
    <div>
      <h2>{title} Talent</h2>
      <Formik
        // validationSchema={{

        // }}
        initialValues={{
          firstName: state?.firstName || "",
          lastName: state?.lastName || "",
          email: state?.email || "",
          phone: state?.phone || "",
          industry: state?.industry || "",
          companyType: state?.companyType || "",
          companySize: state?.companySize || "",
          status: state?.status || "Active",
          linkedInUrl: state?.linkedInUrl || "",
          employeeIdentificationNumber: state?.employeeIdentificationNumber || "",
          // password: "",
          // confirm_password: "",
          dateOfBirth: state?.dateOfBirth || "",
          gender: state?.gender || "",
          currentLocation: state?.currentLocation || "",
          permanentAddress: state?.permanentAddress || "",
          nationalId: state?.nationalId || "",
          workMode: state?.workMode || [],
          webSiteUrl: state?.webSiteUrl || "",
          driversLicense: state?.driversLicense || "",
          summary: state?.summary || "",
          educations: state?.educations || "",
          workHistoryExperience: state?.workHistoryExperience?.[0]?.jobDescription || "",
          portfolioLink: state?.portfolioLink || "",
          desiredCompensation: state?.desiredCompensation || "",
          specificPriorities: state?.specificPriorities || ""
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          try {
            const { data } = await updateTalentApi(state._id, values);
            if (data) {
              toast.success('Update was successful');
              navigate(-1)
            }
          } catch (error) {
            errorHandler(error, Object.values(error.errors)[0])
          }
          setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          submitForm,
          isSubmitting,
          getFieldProps,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} className={classes.container}>
            <div className={classes["form-group"]}>
              <label>
                First Name*
                <input required type="text" placeholder="Jane" className="mt-sm" {...getFieldProps("firstName")} />
              </label>
              <label>
                Last Name*
                <input required type="text" placeholder="Jane" className="mt-sm" {...getFieldProps("lastName")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Email Address*
                <input required type="email" placeholder="Janedoe@awasource.com" className="mt-sm" {...getFieldProps("email")} />
              </label>
              <label>
                Phone Number*
                <input required type="text" placeholder="Phone Number" className="mt-sm" {...getFieldProps("phone")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <div className={classes.radios}>
                <p>Gender</p>
                <div>
                  <RadioInput
                    text="Male"
                    value="Male"
                    name="gender"
                    checked={values.gender === "Male"}
                    onChange={(e) => {
                      if (e.target.checked)
                        setFieldValue('gender', e.target.value);
                    }}
                  />

                  <RadioInput
                    text="Female"
                    value="Female"
                    name="gender"
                    checked={values.gender === "Female"}
                    onChange={(e) => {
                      if (e.target.checked)
                        setFieldValue('gender', e.target.value);
                    }}
                  />
                </div>
              </div>
              <label>
                Date of Birth
                <input type="date" placeholder="DD/MM/YYYY" className="mt-sm" {...getFieldProps("dateOfBirth")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Current Location
                <input type="text" placeholder="Current Location" className="mt-sm" {...getFieldProps("currentLocation")} />
              </label>
              <label>
                Permanent Address
                <input type="text" placeholder="Permanent Address" className="mt-sm" {...getFieldProps("permanentAddress")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Driver's License Number
                <input type="text" placeholder="Driver's License Number" className="mt-sm" {...getFieldProps("driversLicense")} />
              </label>
              <label>
                National Id Card Number
                <input type="text" placeholder="National Id Card Number" className="mt-sm" {...getFieldProps("nationalId")} />
              </label>
            </div>
            <div className={classes.radios}>
              <p className="font-normal">Work Mode</p>
              <div>
                <CheckInput
                  text="Open to relocate"
                  value="Open to relocate"
                  // name="workMode"
                  checked={values.workMode?.includes("Open to relocate")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue('workMode', [...values.workMode, e.target.value]);
                    } else {
                      setFieldValue('workMode', values.workMode.filter(w => w !== e.target.value));
                    }
                  }}
                />
                <CheckInput
                  text="Work Remotely"
                  value="Work Remotely"
                  // name="workMode"
                  checked={values.workMode?.includes("Work Remotely")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue('workMode', [...values.workMode, e.target.value]);
                    } else {
                      setFieldValue('workMode', values.workMode.filter(w => w !== e.target.value));
                    }
                  }}
                />
                <CheckInput
                  text="Onsite"
                  value="Onsite"
                  // name="workMode"
                  checked={values.workMode?.includes("Onsite")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue('workMode', [...values.workMode, e.target.value]);
                    } else {
                      setFieldValue('workMode', values.workMode.filter(w => w !== e.target.value));
                    }
                  }}
                />
              </div>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Website Url
                <input type="text" placeholder="Website Url" className="mt-sm" {...getFieldProps("webSiteUrl")} />
              </label>
              <label>
                LinkedIn Url
                <input type="text" placeholder="LinkedIn Url" className="mt-sm" {...getFieldProps("linkedInUrl")} />
              </label>
            </div>

            <div className={classes["form-group"]}>
              <label className="col-span-2">
                Summary
                <textarea placeholder="I am lorem ipsum etc etc" className="mt-sm" rows={5}  {...getFieldProps("summary")} />
              </label>
            </div>

            <hr className="my-10" />

            <div className={`${classes["form-group"]} !grid-cols-1`}>
              <label>
                School Name
                <input type="text" placeholder="School Name" className="mt-sm" {...getFieldProps("firstName")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Degree
                <select name="emailType" onChange={handleChange} className="mt-sm">
                  <option>None</option>
                  <option>Bachelors</option>
                </select>
              </label>
              <label>
                Field of Study
                <select name="emailType" onChange={handleChange} className="mt-sm">
                  <option>None</option>
                  <option>Computer Science</option>
                </select>
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Start Date
                <input type="date" placeholder="DD/MM/YYYY" className="mt-sm" {...getFieldProps("start")} />
              </label>
              <label>
                End Date
                <input type="date" placeholder="DD/MM/YYYY" className="mt-sm" {...getFieldProps("end")} />
              </label>
            </div>

            <hr className="my-10" />

            <h2 className="md:text-xl font-semibold mb-sm">Work Experience</h2>
            <div className={`${classes["form-group"]} !grid-cols-1`}>
              <label>
                Job Description
                <textarea placeholder="What was the nature of your job" className="mt-sm" rows={5}  {...getFieldProps("workHistoryExperience")} />
              </label>
            </div>
            <div className={`${classes["form-group"]} !grid-cols-1`}>
              <label>
                Portfolio Link (Enter the link to show us what you have done before)
                <input type="text" placeholder="Portfolio Link" className="mt-sm" {...getFieldProps("portfolioLink")} />
              </label>
            </div>

            <hr className="my-10" />

            <h2 className="md:text-xl font-semibold mb-sm">Work Compensation</h2>

            <p className="mb-sm">Desired compensation</p>
            <div className={classes['range-container']}>
              <input
                type="range"
                min={min}
                max={max}
                value={minVal}
                ref={minValRef}
                onChange={(event) => {
                  const value = Math.min(+event.target.value, maxVal - 1);
                  setMinVal(value);
                  event.target.value = value.toString();
                }}
                className={`${classes.thumb} ${classes.thumb__zindex3}}`}
              />
              <input
                type="range"
                min={min}
                max={max}
                value={maxVal}
                ref={maxValRef}
                onChange={(event) => {
                  const value = Math.max(+event.target.value, minVal + 1);
                  setMaxVal(value);
                  event.target.value = value.toString();
                }}
                className={`${classes.thumb} ${classes.thumb__zindex4}`}
              />

              <div className={classes.slider}>
                <div className={classes.slider__track} />
                <div ref={range} className={classes.slider__range} />
                <div className={classes.minValue}>₦ 0</div>
                <div className={classes.maxValue}>₦ 500k</div>
                <div className={classes.slider__left_value}>₦ {minVal}k</div>
                <div className={classes.slider__right_value}>₦ {maxVal}k</div>
              </div>
            </div>

            {/* <div className={classes["form-group"]}>
              <label>
                Password*
                <input type="text" placeholder="Password" className="mt-sm" {...getFieldProps("password")} />
              </label>
              <label>
                Confirm Password*
                <input type="text" placeholder="Confirm Password" className="mt-sm" {...getFieldProps("confirm_password")} />
              </label>
            </div> */}

            <div className={classes.btn}>
              <Button variant="white" isLink to="/dashboard/users/talents">
                Cancel
              </Button>
              <Button type="submit" variant="yellow" onClick={submitForm} disabled={isSubmitting}>Save</Button>
              {/* <Button variant="blue">Save and notify</Button> */}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddNewTalent;
