import Button from "../../../../components/UI/Button/Button";
import RadioInput from "../../../../components/UI/RadioInput/input";
import classes from "./index.module.css";
import { Formik } from "formik";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { errorHandler } from "../../../../utils/errorHandler";
import { addUserGroupApi, updateUserGroupApi } from "../../../../apis";

const OPTIONS = [
  {
    title: "Roles",
    option: ["Select All"],
  },
  {
    title: "Dashboard",
    option: ["Dashboard Summary", "Activity Summary", "Job Summary"],
  },
  {
    title: "Talent User Management",
    option: ["Add", "Edit", "Block", "Email", "Delete", "View/Access"],
  },
  {
    title: "Client User Management",
    option: ["Add", "Edit", "Block", "Email", "Delete", "View/Access"],
  },
  {
    title: "Admin User Management",
    option: [
      "Add",
      "Edit",
      "Block",
      "Email",
      "Delete",
      "View/Access",
      "List/View Admin user group",
      "Add Admin user group",
    ],
  },
  {
    title: "Jobs",
    option: ["Edit", "Delete", "View", "Create Job"],
  },
  {
    title: "Subscriptions",
    option: ["Edit", "Delete", "View"],
  },
  {
    title: "Transactions",
    option: ["Edit", "Delete", "View"],
  },
  {
    title: "Tests",
    option: ["Edit", "Delete", "View"],
  },
  {
    title: "Emails",
    option: ["Edit", "Delete", "View"],
  },
  {
    title: "Supports",
    option: ["Edit", "Delete", "View"],
  },
];
const AddNewGroup = ({ title = "Add" }) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        userGroupName: state?.userGroupName || "",
        status: state?.status || "Active",
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        try {
          const { data } = title === "Add" ? await addUserGroupApi(values) : await updateUserGroupApi(state._id, values);
          if (data) {
            toast.success(`${title} was successful`);
            navigate(-1)
          }
        } catch (error) {
          errorHandler(error, Object.values(error.errors)[0])
        }
        setSubmitting(false)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        getFieldProps,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} className={classes.container}>
          <h2>{title} Admin User Group</h2>
          <div className={classes.container}>
            <div>
              <label>
                User Group Name*
                <input required type="text" placeholder="Customer Support" className="mt-sm" {...getFieldProps("userGroupName")} />
              </label>
            </div>
            <div className={classes.radios}>
              <p>Status</p>
              <div>
                <RadioInput
                  text="Active"
                  value="Active"
                  name="status"
                  checked={values.status === "Active"}
                  onChange={(e) => {
                    if (e.target.checked)
                      setFieldValue('status', e.target.value);
                  }}
                />

                <RadioInput
                  text="Inactive"
                  value="Inactive"
                  name="status"
                  checked={values.status === "Inactive"}
                  onChange={(e) => {
                    if (e.target.checked)
                      setFieldValue('status', e.target.value);
                  }}
                />
              </div>
            </div>
            {OPTIONS.map((item, index) => (
              <div key={index} className={classes.group}>
                <p>{item.title}</p>
                <div className={classes.checks}>
                  {item.option.map((item, idx) => (
                    <label key={idx}>
                      <input type="checkbox" />
                      {item}
                    </label>
                  ))}
                </div>
              </div>
            ))}
            <div className={classes.btn}>
              <Button variant="white" isLink to="/dashboard/admin/user-group">
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting} variant="blue">Submit</Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddNewGroup;
