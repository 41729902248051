import classes from "./Skills.module.css";

const Skills = ({ data }) => {
  return (
    <div className="relative" type="container">
      <h2>Skills</h2>
      <div>
        {data?.primarySkills?.length < 1 && data?.secondarySkills?.length < 1 && <div className={`t-14 center mt-4`}>
          No skill provided
        </div>}
        {data?.primarySkills?.map((item, index) => (
          <span className={`t-14 center ${classes.tag}`} key={index}>
            {item}
          </span>
        ))}
        {data?.secondarySkills?.map((item, index) => (
          <span className={`t-14 center ${classes.tag}`} key={index}>
            {item}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Skills;
