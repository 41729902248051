import { adminSlice as slice } from '../slices/adminSlice';
import { dispatch } from '../store';
import { errorHandler } from '../../utils/errorHandler';
import { getAdminsApi, getUserGroupsApi } from '../../apis';

export function getAdmins(page = 1, limit = 10) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await getAdminsApi(page, limit);

      dispatch(slice.actions.getAdminsSuccess(data));
    } catch (err) {
      errorHandler(err, "Failed to get admin users!", false);
      dispatch(slice.actions.hasError(err));
    }
  };
}

export function getAdminUserGroups(page = 1, limit = 10) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await getUserGroupsApi(page, limit);

      dispatch(slice.actions.getAdminsSuccess(data));
    } catch (err) {
      errorHandler(err, "Failed to get admin user groups!", false);
      dispatch(slice.actions.hasError(err));
    }
  };
}
