import Button from "../../../../components/UI/Button/Button";
import RadioInput from "../../../../components/UI/RadioInput/input";
import classes from "./index.module.css";
import { useLocation, useNavigate } from "react-router";
import { Formik } from "formik";
import { errorHandler } from "../../../../utils/errorHandler";
import { toast } from "react-toastify";
import { addAdminApi, updateAdminApi } from "../../../../apis";

const AddNewAdmin = ({ title = "Add" }) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        firstName: state?.firstName || "",
        lastName: state?.lastName || "",
        email: state?.email || "",
        phone: state?.phone || "",
        companyName: state?.companyName || "",
        password: "",
        admin_user_group: state?.admin_user_group || "",
        status: state?.status || "Active",
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        try {
          const { data } = title === "Add" ? await addAdminApi(values) : await updateAdminApi(state._id, values);
          if (data) {
            toast.success(`${title} was successful`);
            navigate(-1)
          }
        } catch (error) {
          errorHandler(error, Object.values(error.errors)[0])
        }
        setSubmitting(false)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        getFieldProps,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} className={classes.container}>
          <h2>{title} Admin</h2>
          <div className={classes.container}>
            <div className={classes["form-group"]}>
              <label>
                First Name*
                <input required type="text" placeholder="Jane" className="mt-sm" {...getFieldProps("firstName")} />
              </label>
              <label>
                Last Name*
                <input required type="text" placeholder="Jane" className="mt-sm" {...getFieldProps("lastName")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Company Name*
                <input required type="text" placeholder="Awasource" className="mt-sm" {...getFieldProps("companyName")} />
              </label>
              <label>
                Email Address*
                <input required type="text" placeholder="Janedoe@awasource.com" className="mt-sm" {...getFieldProps("email")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Phone Number*
                <input type="text" placeholder="Phone Number" className="mt-sm" {...getFieldProps("phone")} />
              </label>
              <label>
                User Admin Group*
                <select required name="admin_user_group" onChange={handleChange} className="mt-sm" {...getFieldProps("admin_user_group")}>
                  <option>None</option>
                  <option>Admin</option>
                  <option>Talent</option>
                  <option>Client</option>
                  <option>Marketing</option>
                </select>
              </label>
            </div>
            <div className={classes.radios}>
              <p>Status</p>
              <div>
                <RadioInput
                  text="Active"
                  value="Active"
                  name="status"
                  checked={values.status === "Active"}
                  onChange={(e) => {
                    if (e.target.checked)
                      setFieldValue('status', e.target.value);
                  }}
                />

                <RadioInput
                  text="Inactive"
                  value="Inactive"
                  name="status"
                  checked={values.status === "Inactive"}
                  onChange={(e) => {
                    if (e.target.checked)
                      setFieldValue('status', e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Password*
                <input type="text" placeholder="Password" className="mt-sm" {...getFieldProps("password")} />
              </label>
              <label>
                Confirm Password*
                <input type="text" placeholder="Confirm Password" className="mt-sm" {...getFieldProps("confirm_password")} />
              </label>
            </div>

            <div className={classes.btn}>
              <Button variant="white" isLink to="/dashboard/admin/admin-list">
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting} variant="yellow">Save</Button>
              <Button type="submit" disabled={isSubmitting} variant="blue">Save and notify</Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddNewAdmin;
