import { useState } from "react";
import Button from "../../../components/UI/Button/Button";
import classes from "./SelfDevelopment.module.css";
import Plus from "../../../assets/images/icons/plus.svg";
import Play from "../../../assets/images/icons/play.svg";
import Delete from "../../../assets/images/icons/delete-filled.svg";
import Tick from "../../../assets/images/icons/tick.svg";

const SelfDevelopment = () => {
  const [payload, setPayload] = useState({
    name: "",
    emailType: "",
    subject: "",
    "sender email": "",
    "sender name": "",
    "reply mail": "",
    template: "",
    status: "Active",
  });
  const handleChange = (e) =>
    setPayload({ ...payload, [e.target.name]: e.target.value });

  return (
    <div>
      <div className="flex flex-col md:flex-row gap-3 md:items-center justify-between">
        <h2>Self Development controls</h2>
        <div className={classes.container}>
          <select name="emailType" onChange={handleChange} className="w-[458px] max-w-full">
            <option>Frontend Developers</option>
          </select>
        </div>
      </div>

      <form className={classes.form}>
        <div className={`${classes.container} !flex flex-col md:flex-row gap-3 md:gap-4 items-end`}>
          <label className="mr-auto flex-grow md:!mb-0">
            Video Link*
            <input type="text" onChange={handleChange} name="name" />
          </label>

          <Button variant="white" to="#" className="!min-w-[50px] w-[50px] h-[50px] md:ml-6">
            <img src={Play} alt="Play" />
          </Button>
          <Button variant="white" to="#" className="!min-w-[50px] w-[50px] h-[50px]">
            <img src={Tick} alt="Tick" />
          </Button>
          <Button variant="white" to="#" className="!min-w-[50px] w-[50px] h-[50px]">
            <img src={Delete} alt="Delete" />
          </Button>
          <Button variant="white" to="#" className="!min-w-[50px] w-[50px] h-[50px]">
            <img src={Plus} alt="Plus" />
          </Button>
        </div>

        <div className={`${classes.container} !flex flex-col md:flex-row gap-3 md:gap-4 items-end`}>
          <label className="mr-auto flex-grow md:!mb-0">
            Video Link*
            <input type="text" onChange={handleChange} name="name" />
          </label>

          <Button variant="white" to="#" className="!min-w-[50px] w-[50px] h-[50px] md:ml-6">
            <img src={Play} alt="Play" />
          </Button>
          <Button variant="white" to="#" className="!min-w-[50px] w-[50px] h-[50px]">
            <img src={Tick} alt="Tick" />
          </Button>
          <Button variant="white" to="#" className="!min-w-[50px] w-[50px] h-[50px]">
            <img src={Delete} alt="Delete" />
          </Button>
          <Button variant="white" to="#" className="!min-w-[50px] w-[50px] h-[50px]">
            <img src={Plus} alt="Plus" />
          </Button>
        </div>

        <div className={classes.btn}>
          <Button variant="white" isLink to="#">
            Cancel
          </Button>
          <Button variant="blue">Submit</Button>
        </div>
      </form>
    </div>
  );
};

export default SelfDevelopment;
