import classes from "./WorkHistory.module.css";
import { Briefcase, Line } from "../../../../../../constants";

const WorkHistory = ({ data }) => {
  return (
    <div className={`relative ${classes.mainContainer}`} type="container">
      {data?.workHistoryExperience?.length < 1 && <div className={`t-14 center`}>
        No work experience provided
      </div>}
      {data?.workHistoryExperience?.map((item, index) => (
        <div className={`flex`} key={index}>
          <div className={`flex column ${classes.line}`}>
            <div className={classes.briefcase}>
              <Briefcase />
            </div>
            <div className={`flex column ${classes.track}`}>
              <span>{item?.startWorkingDate}</span>
              <Line />
              <span>
                {!!item?.currentlyWorking ? "Current" : item?.endWorkingDate}
              </span>
            </div>
          </div>
          <div>
            <div>
              <h3>{item?.jobTitle}</h3>
              <p className={classes.company}>{item?.companyName}</p>
              <div className={`flex ${classes.align}`}>
                <span className={classes.industry}>Industry</span>
                <span className={classes.span}></span>
                <span>{item?.industry}</span>
              </div>
            </div>
            <div className="mt-md">
              <p>{item?.jobDescription}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WorkHistory;
