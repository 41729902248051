import classes from "./WorkPriorities.module.css";

const WorkPriorities = ({ data }) => {
  return (
    <div className="relative" type="container">
      <h3>Work Priorities</h3>
      <div>
        {data?.specificPriorities?.length < 1 && <div className={`t-14 center mt-4`}>
          No work priorities provided
        </div>}
        {data?.specificPriorities?.map((item, index) => (
          <span className={`t-14 center ${classes.tag}`} key={index}>
            {item}
          </span>
        ))}
      </div>
    </div>
  );
};

export default WorkPriorities;
