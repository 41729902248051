import classes from "./Resume.module.css";
import Download from "../../../../../../assets/images/icons/download.svg";

const Resume = ({ data }) => {
  return (
    <div className="relative mt-md" type="container">
      <h3>Download Resume</h3>
      <div className={`flex mt-sm ${classes.main}`}>
        <a className={`flex ${classes.download}`} href={data?.pdfUrl} download>
          <img src={Download} alt="download" />
          Download
        </a>
        <span>{data?.firstName}'s Resume</span>
      </div>
    </div>
  );
};

export default Resume;
