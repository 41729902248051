import classes from "./Transaction.module.css";
import { TRANSACTION_ARRAY } from "../../../constants";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState } from "react";

const Transaction = () => {
  const BTN_ARRAY = [
    {
      text: "All",
      count: 0,
    },
    {
      text: "Credit",
      count: 0,
    },
    {
      text: "Debit",
      count: 0,
    },
  ];

  const [currentPage, setCurrentPage] = useState("All");

  return (
    <div>
      <h2>Transaction</h2>
      <div className={`flex  mt-sm ${classes.btn} !justify-start gap-10`}>
        {BTN_ARRAY.map((item, i) => (
          <button
            key={`btn-${i}`}
            onClick={() => setCurrentPage(item.text)}
            className={`py-3 ${currentPage === item.text ? classes.active : ""}`}
          >
            {item.text} {item.count}
          </button>
        ))}
      </div>

      <div className={`mt-md ${classes.table}`}>
        <div className={`flex flex-col md:flex-row md:items-center gap-4 mb-md ${classes.btn}`}>
          <h2>Transaction manager</h2>
          <input placeholder="Search" />
          {/* <div className={`flex flex-col sm:flex-row sm:items-center gap-4 ${classes.otherbutton}`}>
            <Button variant="white">Delete</Button>
          </div> */}
        </div>


        <DataTable
          emptyMessage="No record found"
          rows={10}
          paginator={TRANSACTION_ARRAY.length > 10}
          value={TRANSACTION_ARRAY || []}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: '100%' }}
        >
          <Column className="pr-3 text-center"
            field="title"
            header={
              <input type="checkbox" className="w-[18px] h-[18px] rounded-s" />}
            body={(item) => (
              <div className="text-left">
                <input type="checkbox" className="w-[18px] h-[18px] rounded-s" />
              </div>
            )}
          />
          <Column className="p-5 text-center"
            field="name"
            header="Name"
            body={(item) => (
              <div className="text-left">
                <p>{item.name}</p>
                <small>{item.email}</small>
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="reference"
            header="Reference number/ID"
            body={(item) => (
              <div>
                {item.reference}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="address"
            header="Address"
            body={(item) => (
              <div className={classes.subject}>{item.address}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="phone"
            header="Phone number"
            body={(item) => (
              <div>
                {item.phone}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="amount"
            header="amount"
            body={(item) => (
              <div>
                {item.amount}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="date"
            header="Date"
            body={(item) => (
              <div>
                {item.date}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="time"
            header="Time"
            body={(item) => (
              <div>
                {item.time}
              </div>)}
            sortable
          />
        </DataTable>
      </div>
    </div>
  );
};

export default Transaction;
