import Button from "../../../components/UI/Button/Button";
import classes from "./Support.module.css";
import { SUPPORT_ARRAY } from "../../../constants";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState } from "react";

const Support = () => {
  const BTN_ARRAY = [
    {
      text: "Unread",
      count: 0,
    },
    {
      text: "Read",
      count: 0,
    },
    {
      text: "Archived",
      count: 0,
    },
    {
      text: "Draft",
      count: 0,
    },
  ];

  const [currentPage, setCurrentPage] = useState("Unread");

  return (
    <div>
      <h2>Support</h2>
      <div className={`flex  mt-sm ${classes.btn} !justify-start gap-10`}>
        {BTN_ARRAY.map((item, i) => (
          <button
            key={`btn-${i}`}
            onClick={() => setCurrentPage(item.text)}
            className={`py-3 ${currentPage === item.text ? classes.active : ""}`}
          >
            {item.text} {item.count}
          </button>
        ))}
      </div>

      <div className={`mt-md ${classes.table}`}>
        <div className={`flex flex-col md:flex-row md:items-center gap-4 mb-md ${classes.btn}`}>
          <h2>Support manager</h2>
          <input placeholder="Search" />
          <div className={`flex flex-col sm:flex-row sm:items-center gap-4 ${classes.otherbutton}`}>
            <Button variant="white">Delete</Button>
            <Button
              variant="yellow"
            // onClick={() => navigate("/dashboard/add-email")}
            >
              Compose
            </Button>
          </div>
        </div>


        <DataTable
          emptyMessage="No record found"
          rows={10}
          paginator={SUPPORT_ARRAY.length > 10}
          value={SUPPORT_ARRAY || []}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: '100%' }}
        >
          <Column className="pr-3 text-center"
            field="title"
            header={
              <input type="checkbox" className="w-[18px] h-[18px] rounded-s" />}
            body={(item) => (
              <div className="text-left">
                <input type="checkbox" className="w-[18px] h-[18px] rounded-s" />
              </div>
            )}
          />
          <Column className="p-5 text-center"
            field="title"
            header="Title"
            body={(item) => (
              <div className="text-left">
                <p>{item.title}</p>
                <small>{item.message}</small>
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="sent"
            header="Received"
            body={(item) => (
              <div className={classes.subject}>{item.sent}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="image"
            header="Author"
            body={(item) => (
              <div className="rounded-full bg-[#F5F6FA] h-[38px] w-[38px] flex items-center justify-center">{item.image?.charAt(0)}
              </div>)}
          />
          <Column className="p-5"
            field="lastSeen"
            header="Last Seen"
            body={(item) => (
              <div>
                {item.lastSeen}
              </div>)}
            sortable
          />
        </DataTable>
      </div>
    </div>
  );
};

export default Support;
